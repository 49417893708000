import store from '../../store/index'

export default {
    open(ingresso){
        store.commit('modalIngressoEmissao',{
            display: true,
            ingresso:ingresso
        })
    },
    close(){
        store.commit('modalIngressoEmissao', {
            display: false,
            ingresso: {}
        });
    },
}