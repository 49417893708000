<template>
  <div v-if="store.getters.modalPromoterMovimentacao.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class=" lg:w-3/4 w-full lg:h-3/4 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 "
      role="dialog" id="modal">

      <div class="bg-white rounded-lg h-full w-full overflow-auto">
        <div class="flex flex-col">
          <div class="title1 text-SC_Azul text-center p-5 mt-3">
            <h1>Movimentações do promoter - {{ promoterV.promoter }}</h1>

            <div class="flex flex-col w-full mt-5">
              <div class="overflow-auto w-full text-left">
                <DatatableAgGrid :rows="dadosPromoterMovimentacao" :columns="headersPromoterMovimentacao"
                  :defaultColDef="defaultColDef" :type="'PromoterMovimentacao'" :pinnedBottomRowData="total"
                  :key="componentKey" />
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="flex flex-row justify-center mt-2 noprint">
        <button class="botaoFechar" @click="close(false)">Fechar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, watch, inject, onMounted } from 'vue';
import moment from "moment-timezone";
import DatatableAgGrid from "@/components/Elements/dataTable/DatatableAgGrid.vue";
import tableFunctions from "@/components/Elements/tableFunctions.vue";

import { formatarMonetarioBRL, formatarDataIsoData, formatarData, formatarDataIsoDataHora, formatarDataHoraSemFusoHorario, formatarDataHora } from "@/services/formater";
import { GET, POST } from "@/services/api";

export default {
  components: {
    DatatableAgGrid,
    /* eslint-disable */
    tableFunctions
  },
  setup() {
    const store = useStore();
    const promoterV = ref()
    const eventoInfo = inject("evento")
    const eventoNome = ref('')
    const tipoBotao = ref('')
    const nomePromoter = ref('')
    const total = ref([])
    const componentKey = ref(0)
    const modalVisualizarEmissao = inject("modalVisualizarEmissao")
    const modalIngresso = inject("modalIngressoEmissao")
    const loader = inject("loading");
    const alert = inject("alert");
    const eventoID = eventoInfo.getEventoID()


    const close = (reload) => {
      if (reload)
        store.getters.modalPromoterMovimentacao.callbackOK(reload);
      else
        store.getters.modalPromoterMovimentacao.callbackOK(false);
    };

    const confirmar = async () => {
      store.getters.modalPromoterMovimentacao.callbackOK();
    };

    const capitalizeFirstLetter = (word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    const visualizarEmissao = (rows) => {
      modalVisualizarEmissao.open(rows)
    }

    const visualizarQRCode = async (rows) => {
      const id_emissao = rows.id_emissao

      try {
        loader.open()
        const emissao = await GET(`promoter_movements/${id_emissao}/ingresso/${eventoID}`)

        if (emissao.success)
          modalIngresso.open(emissao.data[0])
        else {
          alert.open("Alerta", emissao.msg, "warning")
        }
      } catch (e) {
        if (e !== undefined)
          alert.open("Alerta", e, "warning")
        else
          alert.open("Alerta", "Aconteceu um erro ao visualizar o ingresso (Verifique o <b>Status</b> do ingresso)", "warning")
      } finally {
        loader.close()
      }
    }

    const cancelarEmissao = async (rows) => {
      try {
        const response = await POST(`promoter_movements/cancelar_emissao/${rows.id_emissao}/${eventoID}`)

        if (response.success) {
          alert.open("Sucesso", "Emissão cancelada com sucesso", "success")
        } else {
          alert.open("Erro", response.msg, "warning")
        }

      } catch (e) {
        alert.open("Erro", e, "warning")
        console.log(e)
      }
      finally {
        store.commit('modalCancelarHistoricoEmissao', {
          display: false,
          emissao: {},
          callbackOK: () => { }
        })

        close(true)
        componentKey.value += 1

      }
    }

    const openModalCancelarHistoricoEmissao = (item) => {
      store.commit('modalCancelarHistoricoEmissao', {
        display: true,
        emissao: item,
        callbackOK: () => cancelarEmissao(item)
      })
    }

    watch(
      () => [store.getters.modalPromoterMovimentacao.display],
      async () => {
        if (store.getters.modalPromoterMovimentacao.display) {
          promoterV.value = store.getters.modalPromoterMovimentacao.promoter
          nomePromoter.value = promoterV.value.promoter
          dadosPromoterMovimentacao.value = []


          let quantidade = 0
          let valor = 0
          let valorTotal = 0
          let devido = 0

          const response = await GET(`promoter_movements/${promoterV.value.id_promoter_usuario}/historico/${eventoInfo.getEventoID()}/evento`)

          let status = ''

          if (response.success) {

            response.dataPagamento.map((item) => {
              if (item.id_status === 2)
                status = 'Cancelado'
              else if (item.id_status === 1 && item.finished_at === null) {
                status = 'Processando'
                valorTotal += parseFloat(item.valor)
              }
              else if (item.id_status === 1 && item.finished_at !== null) {
                status = 'Ativo'
                valorTotal += parseFloat(item.valor)
              }

              dadosPromoterMovimentacao.value.push({
                id_emissao: item.id,
                nome: 'Pagamento',
                devido: '-/-',
                valor: parseFloat(item.valor),
                quantidade: '-/-',
                comissao: String(item.devido),
                descricao: 'Pagamento',
                permissions: { perm_visualiza_qrcode: true, perm_cancel: true },
                tipo: capitalizeFirstLetter(item.tipo),
                data: item.data,
                status: status,
                saldo: '-/-'
              })

            })


            response.data.map((item) => {
              quantidade += 1
              if (item.id_status === 2)
                status = 'Cancelado'
              else if (item.id_status === 1 && item.finished_at === null) {
                status = 'Processando'
                valor += parseFloat(item.saldo)
                valorTotal += parseFloat(-item.valor)
                devido += parseFloat(item.devido)

              }
              else if (item.id_status === 1 && item.finished_at !== null) {
                status = 'Ativo'
                valor += parseFloat(item.saldo)
                valorTotal += parseFloat(-item.valor)
                devido += parseFloat(item.devido)
              }


              dadosPromoterMovimentacao.value.push({
                id_emissao: item.id,
                nome: item.detalhes.nome,
                valor: parseFloat(-item.valor),
                devido: parseFloat(item.devido),
                quantidade: 1,
                descricao: item.produto.detalhes,
                tipo: capitalizeFirstLetter(item.tipo),
                comissao: Number(item.promoterUsuario.porcentagem).toFixed(2),
                permissions: { perm_visualiza_qrcode: true, perm_cancel: true },
                data: item.data,
                status: status,
                email: item.detalhes.email,
                documento: item.detalhes.cpf,
                saldo: parseFloat(item.saldo)
              })
              status = ''
            })
          }

          /*response.data.map((item) => {
            quantidade += parseInt(item.quantidade)
            valor += parseFloat(item.devido);

            dadosPromoterMovimentacao.value.push(
              {
                data: item.data,
                produto: item.produto,
                quantidade: parseInt(item.quantidade),
                valor: parseFloat(item.devido),
                nomePromoter: nomePromoter.value,
                tipo: item.tipo === 'emissao' ? 'Emissão' : capitalizeFirstLetter(item.tipo),
              }
            )
          })*/

          total.value = [{
            data: 'Total',
            descricao: 'Todos',
            devido: devido,
            quantidade: quantidade,
            valor: valorTotal,
            saldo: valor,
            nomePromoter: nomePromoter.value,
            status: 'Todos',
            tipo: 'Todos',
            total: true
          }]


          componentKey.value += 1

        } else {
          promoterV.value = {}
        }
      })


    const headersPromoterMovimentacao = ref([
      {
        field: 'data',
        headerName: 'Data Movimentação',
        filter: 'agDateColumnFilter',
        minWidth: 180,
        sort: 'desc',
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por Data da movimentação...',
        },
        valueFormatter: (params) => {
          if (params.data.tipo === 'Pagamento') {
            return formatarDataHora(params.data.data)
          }
          if (params.value !== 'Total') {
            return formatarDataHoraSemFusoHorario(params.data.data)
          }
          return 'Total'
        },
        cellClass: 'wrap-text leading-6 flex items-center',
        cellStyle: params => {
          if (params.data.total)
            return { color: 'black', fontWeight: 600, backgroundColor: "#D9D9D9" };
          if (params.data.status === 'Cancelado')
            return { color: 'black', backgroundColor: "#ffbfc0" };

          return { color: 'black', fontWeight: 600 };
        }
      },
      {
        field: 'descricao',
        headerName: 'Detalhes',
        minWidth: 140,
        filter: 'agTextColumnFilter',
        cellClass: 'wrap-text leading-6 flex items-center',
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por Detalhes...',
        },
        cellStyle: params => {
          if (params.data.total)
            return { color: 'black', fontWeight: 600, backgroundColor: "#D9D9D9" };
          if (params.data.status === 'Cancelado')
            return { color: 'black', backgroundColor: "#ffbfc0" };

        }
      },
      {
        field: 'quantidade',
        headerName: 'Quantidade',
        filter: 'agNumberColumnFilter',
        minWidth: 30,
        cellClass: 'wrap-text leading-6 flex items-center',
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por Quantidade...',
        },
        cellStyle: params => {
          if (params.data.total)
            return { color: 'black', fontWeight: 600, backgroundColor: "#D9D9D9" };
          if (params.data.status === 'Cancelado')
            return { color: 'black', backgroundColor: "#ffbfc0" };

        }
      },
      {
        field: 'valor',
        headerName: 'Valor total',
        filter: 'agNumberColumnFilter',
        minWidth: 170,
        cellClass: 'wrap-text leading-6 flex items-center',
        valueFormatter: (params) => formatarMonetarioBRL(params.value),
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por Valor total...',
        },
        cellStyle: params => {
          if (params.data.total) {
            if (params.value >= 0) {
              return { color: 'green', fontWeight: 600, backgroundColor: "#D9D9D9" };
            } else if (params.value < 0) {
              return { color: 'red', fontWeight: 600, backgroundColor: "#D9D9D9" };
            } else {
              return { color: 'black', fontWeight: 600, backgroundColor: "#D9D9D9" };
            }
          }
          if (params.data.status === 'Cancelado') {
            if (params.value >= 0) {
              return { color: 'green', backgroundColor: "#ffbfc0" };
            } else if (params.value < 0) {
              return { color: 'red', backgroundColor: "#ffbfc0" };
            } else {
              return { color: 'black', fontWeight: 600, backgroundColor: "#ffbfc0" };
            }
          }
          if (params.value >= 0) {
            return { color: 'green' };
          } else if (params.value < 0) {
            return { color: 'red' };
          }

        }
      },
      {
        field: 'devido',
        headerName: 'Comissão',
        filter: 'agNumberColumnFilter',
        minWidth: 130,
        cellClass: 'wrap-text leading-6 flex items-center',
        valueFormatter: (params) => formatarMonetarioBRL(params.value),
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por Comissão...',
        },
        cellStyle: params => {
          if (params.data.total) {
            if (params.value >= 0) {
              return { color: 'green', fontWeight: 600, backgroundColor: "#D9D9D9" };
            } else if (params.value < 0) {
              return { color: 'red', fontWeight: 600, backgroundColor: "#D9D9D9" };
            }
          }
          if (params.data.status === 'Cancelado') {
            if (params.value >= 0) {
              return { color: 'green', backgroundColor: "#ffbfc0" };
            } else if (params.value < 0) {
              return { color: 'red', backgroundColor: "#ffbfc0" };
            } else {
              return { color: 'black', fontWeight: 600, backgroundColor: "#ffbfc0" };
            }
          }
          if (params.value >= 0) {
            return { color: 'green' };
          } else if (params.value < 0) {
            return { color: 'red' };
          }

        }
      },
      {
        field: 'saldo',
        headerName: 'Valor devedor',
        filter: 'agNumberColumnFilter',
        minWidth: 170,
        cellClass: 'wrap-text leading-6 flex items-center',
        valueFormatter: (params) => formatarMonetarioBRL(params.value),
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por Valor devedor...',
        },
        cellStyle: params => {
          if (params.data.total) {
            if (params.value >= 0) {
              return { color: 'green', fontWeight: 600, backgroundColor: "#D9D9D9" };
            } else if (params.value < 0) {
              return { color: 'red', fontWeight: 600, backgroundColor: "#D9D9D9" };
            }
          }
          if (params.data.status === 'Cancelado') {
            if (params.value >= 0) {
              return { color: 'green', backgroundColor: "#ffbfc0" };
            } else if (params.value < 0) {
              return { color: 'red', backgroundColor: "#ffbfc0" };
            } else {
              return { color: 'black', fontWeight: 600, backgroundColor: "#ffbfc0" };
            }
          }
          if (params.value >= 0) {
            return { color: 'green' };
          } else if (params.value < 0) {
            return { color: 'red' };
          }

        }
      },
      {
        field: 'email',
        headerName: 'Email',
        hide: true,
        cellStyle: params => {
          if (params.data.total)
            return { color: 'black', fontWeight: 600, backgroundColor: "#D9D9D9" };
        }
      },
      {
        field: 'nome',
        headerName: 'Nome',
        hide: true,
        cellStyle: params => {
          if (params.data.total)
            return { color: 'black', fontWeight: 600, backgroundColor: "#D9D9D9" };
        }
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        width: 150,
        maxWidth: 230,
        filter: 'agTextColumnFilter',
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por Status...',
        },
        cellStyle: params => {
          if (params.data.total)
            return { color: 'black', fontWeight: 600, backgroundColor: "#D9D9D9" };
          if (params.data.status === 'Cancelado')
            return { color: 'black', backgroundColor: "#ffbfc0" };

        },
        cellClass: 'wrap-text leading-6 flex items-center'
      },
      {
        field: 'tipo',
        headerName: 'Tipo',
        minWidth: 120,
        filter: 'agTextColumnFilter',
        cellClass: 'wrap-text leading-6 flex items-center',
        filterParams: {
          maxNumConditions: 1,
          filterPlaceholder: 'Filtrar por Tipo...',
        },
        cellStyle: params => {
          if (params.data.total)
            return { color: 'black', fontWeight: 600, backgroundColor: "#D9D9D9" };
          if (params.data.status === 'Cancelado')
            return { color: 'black', backgroundColor: "#ffbfc0" };

        }
      },
      {
        field: 'actions',
        headerName: 'Ações',
        sortable: false,
        filter: false,
        cellRenderer: 'tableFunctions',
        cellRendererParams: { type: "EmissaoTela", visualizarQRCode, visualizarEmissao, openModalCancelarHistoricoEmissao },
        minWidth: 150,
        width: 150,
        maxWidth: 150,
        cellStyle: params => {
          if (params.data.total)
            return { color: 'black', fontWeight: 600, backgroundColor: "#D9D9D9" };
          if (params.data.status === 'Cancelado')
            return { color: 'black', backgroundColor: "#ffbfc0" };
          return { color: 'black' }
        },
        cellClass: 'wrap-text leading-6 flex items-center'
      },

    ]);


    const dadosPromoterMovimentacao = ref([

    ]);

    const defaultColDef = ref({
      editable: false,
      filter: false,
      flex: 1,
      autoHeight: true,
      autoSize: true,
      cellClass: 'items-center',
      suppressMovable: true,
      suppressMenu: true,
    });

    eventoNome.value = eventoInfo.getEvento().nome


    return { store, close, eventoNome, promoterV, confirmar, tipoBotao, headersPromoterMovimentacao, defaultColDef, dadosPromoterMovimentacao, total, componentKey };
  },
}
</script>

<style scoped>
.botaoFechar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoFechar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid #408135;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: #408135;
  color: white;
}

.botaoConfirmar:hover {
  background-color: #2a5523;
  color: white;
}

@media print {
  .noprint {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }
}
</style>