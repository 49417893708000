import Filterstore from '../../store/filterStore'


type Filtro = {
    name: string;
    data: filtrochildren[];
}

type filtrochildren = {
    id: number;
    name: string;
}



export default {

    getFilterPromoterProduto() {
        // return all filter
        const ls = localStorage.getItem('filtroPromoterProduto')

        if (ls) {
            const filtro = JSON.parse(ls)
            this.addFilterPromoterProduto(filtro)
        }


        return Filterstore.getters.getfiltroPromoterProduto
    },

    addFilterPromoterProduto(filtro: any) {
        // adicionar

        localStorage.setItem('filtroPromoterProduto', JSON.stringify(filtro))

        Filterstore.commit('setFiltroPromoterProduto', filtro)
    },

    getFilterVenda() {
        // return all filter
        const ls = localStorage.getItem('filtroVenda')

        if (ls) {
            const filtro = JSON.parse(ls)
            this.addFilterVenda(filtro)
        }


        return Filterstore.getters.getfiltroVenda
    },


    addFilterVenda(filtro: any) {
        // adicionar

        localStorage.setItem('filtroVenda', JSON.stringify(filtro))

        Filterstore.commit('setFiltroVenda', filtro)
    },

    removePromoterProduto() {
        localStorage.setItem('filtroPromoterProduto', JSON.stringify([]))

        Filterstore.commit('setFiltroPromoterProduto', [])
    },

    removeVenda() {
        localStorage.setItem('filtroVenda', JSON.stringify([]))

        Filterstore.commit('setFiltroVenda', [])
    },

    addParentNode(parent: any) {
        console.log('addParentNode')
    },


    addChildrenNode(children: any) {
        console.log('addChildrenNode')
    },


    getSelectedVenda() {
        const ls = localStorage.getItem('selectedVenda')

        if (ls) {
            const filtro = JSON.parse(ls)
            this.addSelectedVenda(filtro)
        }


        return Filterstore.getters.getselectedVenda
    },

    addSelectedVenda(filtro: any) {
        // adicionar

        localStorage.setItem('selectedVenda', JSON.stringify(filtro))

        Filterstore.commit('setSelectedVenda', filtro)
    },


    getFilterPortaria() {

        // return all filter
        const ls = localStorage.getItem('filtroPortaria')

        if (ls) {
            const filtro = JSON.parse(ls)
            this.addFilterPortaria(filtro)
        }

        return Filterstore.getters.getfiltroPortaria
    },

    removePortaria() {
        localStorage.setItem('filtroPortaria', JSON.stringify([]))

        Filterstore.commit('setFiltroPortaria', [])
    },


    addFilterPortaria(filtro: any) {
        localStorage.setItem('filtroPortaria', JSON.stringify(filtro))

        Filterstore.commit('setFiltroPortaria', filtro)
    }





}