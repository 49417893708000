<template>
  <template v-if="type === 'PromotersVinculados'">
    <div class="grid grid-cols-4 gap-2 content-center h-full" v-if="params.data.total !== true">
      <button type="button" class="btn btn-success !py-1 flex" @click="editarPromoter">
        <fa :icon="`pencil`"
          style="color: white; background-color: #211261; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;"
          v-tooltip="'Editar Promoter'" />
      </button>
      <button type="button" class="btn btn-success !py-1 flex" @click="realizarPagamento">
        <fa :icon="`dollar`"
          style="color: white; background-color: #408135; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;"
          v-tooltip="'Realizar Pagamento'" />
      </button>
      <button type="button" class="btn btn-success !py-1 flex" @click="promoterMovimentacao">
        <fa :icon="`eye`"
          style="color: white; background-color: #911945; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;"
          v-tooltip="'Visualizar movimentações'" />
      </button>
      <button type="button" class="btn btn-success !py-1 flex" @click="deletarPromoterVinculado">
        <fa :icon="`user-minus`"
          style="color: white; background-color: #FF0000; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;"
          v-tooltip="'Desvincular Promoter'" />
      </button>
    </div>
  </template>

  <template v-if="type === 'PromotersVinculadosLiberar'">
    <div class="flex flex-row gap-2 content-center h-full" v-if="params.data.total !== true">
      <label class="flex items-center relative cursor-pointer select-none"
        v-tooltip="{ content: 'Habilitar/Suspender a liberação <br> de ingressos para este promoter', html: true }">
        <input type="checkbox"
          class="appearance-none transition-colors cursor-pointer w-14 h-5 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-gray-500"
          @change="toggleStatus" v-model="params.data.status" />
        <span class="slider round"></span>
      </label>
      <!--<span class="font-semibold" style="font-size: 0.8rem;">{{ statusButton ? 'Ativado' : 'Suspenso' }}</span>-->
    </div>
  </template>

  <template v-if="type === 'PromotersDisponiveisSaldo'">
    <div class="w-full h-full">
      <div v-if="saldoHabilitado === false">
        <button type="button" class="bg-gray-500 rounded-lg p-1 px-2 text-white !py-1 flex" @click="buscarSaldoPromoter"
          aria-hidden="true" v-tooltip="'Exibir saldo'">
          Exibir saldo
        </button>
      </div>
      <div v-if="saldoHabilitado">
        <span :class="saldo >= 0 ? 'text-green-500' : 'text-red-500'">{{ formatarMonetarioBRL(saldo) }}</span>
      </div>
    </div>
  </template>

  <template v-if="type === 'PromotersDisponiveis'">
    <div class="grid grid-cols-5 gap-2 content-center h-full">
      <button type="button" class="btn btn-success !py-1 flex" @click="editarPromoterDisponivel">
        <fa :icon="`pencil`"
          style="color: white; background-color: #211261; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;"
          v-tooltip="'Editar Promoter'" />
      </button>
      <button type="button" class="btn btn-success !py-1 flex" @click="realizarPagamento">
        <fa :icon="`dollar`"
          style="color: white; background-color: #408135; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;"
          v-tooltip="'Realizar Pagamento'" />
      </button>
      <button type="button" class="btn btn-success !py-1 flex" @click="vincularPromoter" aria-hidden="true"
        v-tooltip="'Vincular Promoter'">
        <fa :icon="`user-plus`" aria-hidden="true"
          style="color: white; background-color: #408135; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;" />
      </button>
      <button type="button" class="btn btn-success !py-1 flex" @click="promoterMovimentacaoGeral" aria-hidden="true">
        <fa :icon="`eye`" aria-hidden="true"
          style="color: white; background-color: #911945; border-radius: 8px; padding: 7px 10px; width: 17px;"
          v-tooltip="'Visualizar todas as movimentações'" />
      </button>
      <button type="button" class="btn btn-success !py-1 flex" @click="excluirPromoterPermanente" aria-hidden="true"
        v-tooltip="'Excluir Promoter'">
        <fa :icon="`xmark`" aria-hidden="true"
          style="color: white; background-color: #FF0000; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;" />
      </button>
    </div>
  </template>

  <template v-if="type === 'Emissao'">
    <div class="grid grid-cols-2 gap-2 content-center h-full" v-if="params.data.tipo !== 'Pagamento' && !params.data.total">
      <button type="button" class="btn !py-1 flex" @click="visualizacaoEmissao(rows)">
        <fa :icon="`eye`"
          style="color: white; background-color: #911945; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;"
          v-tooltip="'Visualizar emissão'" aria-hidden="false" />
      </button>
      <button type="button" class="btn !py-1 flex" @click="visualizacaoQRCode(rows)" v-if="data.status !== 'Cancelado' && data.permissions.perm_visualiza_qrcode">
        <fa :icon="`qrcode`"
          style="color: white; background-color: #211261; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;"
          v-tooltip="'QR Code'" aria-hidden="false" />
      </button>
    </div>
  </template>

  <template v-if="type === 'EmissaoTela'">
    <div class="grid grid-cols-3 gap-2 content-center h-full" v-if="!params.data.total">
      <button type="button" class="btn !py-1 flex" @click="visualizacaoEmissao(rows)" v-if="params.data.tipo !== 'Pagamento'">
        <fa :icon="`eye`"
          style="color: white; background-color: #911945; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;"
          v-tooltip="'Visualizar emissão'" aria-hidden="false" />
      </button>
      <button type="button" class="btn !py-1 flex" @click="visualizacaoQRCode(rows)" v-if="data.status !== 'Cancelado' && data.permissions.perm_visualiza_qrcode && params.data.tipo !== 'Pagamento'" >
        <fa :icon="`qrcode`"
          style="color: white; background-color: #211261; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;"
          v-tooltip="'QR Code'" aria-hidden="false" />
      </button>
      <button type="button" class="btn !py-1 flex" @click="cancelarEmissao(rows)" v-if="data.status !== 'Cancelado' && data.permissions.perm_cancel">
        <fa :icon="`xmark`"
          style="color: white; background-color: #FF0000; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;"
          v-tooltip="'Cancelar emissão'" aria-hidden="false" />
      </button>
    </div>
  </template>

  <template v-if="type === 'LiberarIngresso'">
    <div v-if="data.ingressos" class="w-full">
      <div class="flex flex-row gap-2">
        <div class="backgroundingressos">
          {{ data.ingressos[0].ingresso }} (<span>{{ data.ingressos[0].quantidade }})</span>
        </div>
        <div class="backgroundingressos" v-if="data.ingressos.length > 1 && !ingressosHabilitado[data.id_promoter]">
          <fa icon="plus" style="font-size: 10px;" aria-hidden="true" /> {{ data.ingressos.length - 1 }}
        </div>
        <button v-if="data.ingressos.length > 1" @click="habilitaringressos(data.id_promoter)">
          <fa :icon="ingressosHabilitado[data.id_promoter] ? 'angle-up' : 'angle-down'" class="mx-1 text-lg"
            style="color: #361D54;" aria-hidden="true" />
        </button>
      </div>
      <div class="mt-2 flex" v-if="data.ingressos.length > 1 && ingressosHabilitado[data.id_promoter]">
        <div class="flex flex-col gap-2">
          <div v-for="(item, idx) in data.ingressos.slice(1)" :key="idx">
            <p class="backgroundingressos">{{ item.ingresso }} (<span>{{ item.quantidade
                }}</span>)</p>
          </div>
        </div>
      </div>
    </div>
  </template>

  <template v-if="type === 'Extrato'">
    <div class="flex flex-row items-center justify-center gap-2 pt-2">
      <button type="button" class="btn btn-success flex" @click="reenviarEmailHandler">
        <fa :icon="`paper-plane`" style="color: #361d54; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;"
          aria-label="Reenviar email" title="Reenviar email" aria-hidden="true" v-tooltip="'Reenviar email'" />
      </button>
      <button type="button" class="btn btn-success flex" @click="visualizarIngressoHandler">
        <fa :icon="`qrcode`" style="color: #7d1d54; border-radius: 8px; padding: 7px 10px 7px 10px; width: 17px;"
          aria-label="Vizualizar ingresso" title="Vizualizar ingresso" aria-hidden="true"
          v-tooltip="'Visualizar Ingresso'" />
      </button>
    </div>
  </template>
</template>

<script>
/* eslint-disable */
import { onMounted, ref, inject, watch, h, toRefs } from "vue";
import { useStore } from "vuex";
import { GET } from "@/services/api";
import { formatarMonetarioBRL } from "@/services/formater";

export default {
  setup(props) {
    const { type, data, openModalEditarPromoterDisponivel, openModalEditarPromoter, openModalRealizarPagamentoPromoter, openModalDesvincularPromoter, openModalVincularPromoter, openModalExcluirPromoterPermanente, openModalPromoterMovimentacao, openModalPromoterMovimentacaoGeral, visualizarEmissao, openModalCancelarHistoricoEmissao, visualizarQRCode, controleLiberacao, reenviarEmail, visualizarIngresso } = toRefs(props.params);

    const loader = inject("loading")
    const saldoHabilitado = ref(false)
    const saldo = ref(0)

    const statusButton = ref(data.value.status)
    const ingressosHabilitado = ref({});

    const buscarSaldoPromoter = async () => {
      try {
        loader.open()
        const response = await GET(`promoter_movements/${data.value.id_promoter}/historico`)
        let saldoSomado = 0

        if (response.success) {
          response.data.map((item) => {
            saldoSomado += parseFloat(item.pago) + parseFloat(item.devido)
          })

          saldo.value = saldoSomado
          saldoHabilitado.value = true
        }

      } catch (error) {
        saldoHabilitado.value = false
        console.log(error)
      } finally {
        loader.close()
      }
    }
    const habilitaringressos = (id) => {
      ingressosHabilitado.value[id] = !ingressosHabilitado.value[id];
    };

    const realizarPagamento = () => {
      if (openModalRealizarPagamentoPromoter.value) {
        openModalRealizarPagamentoPromoter.value(data.value);
      }
    };

    const deletarPromoterVinculado = () => {
      if (openModalDesvincularPromoter.value) {
        openModalDesvincularPromoter.value(data.value)
      }
    }

    const vincularPromoter = () => {
      if (openModalVincularPromoter.value) {
        openModalVincularPromoter.value(data.value);
      }
    };

    const excluirPromoterPermanente = () => {
      if (openModalExcluirPromoterPermanente.value) {
        openModalExcluirPromoterPermanente.value(data.value);
      }
    };

    const promoterMovimentacao = () => {
      if (openModalPromoterMovimentacao.value) {
        openModalPromoterMovimentacao.value(data.value)
      }
    }

    const promoterMovimentacaoGeral = () => {
      if (openModalPromoterMovimentacaoGeral.value) {
        openModalPromoterMovimentacaoGeral.value(data.value)
      }
    }

    const visualizacaoEmissao = () => {
      if (visualizarEmissao.value) {
        visualizarEmissao.value(data.value)
      }
    }

    const visualizacaoQRCode = () => {
      if (visualizarQRCode.value) {
        visualizarQRCode.value(data.value)
      }
    }

    const cancelarEmissao = () => {
      if (openModalCancelarHistoricoEmissao.value) {
        openModalCancelarHistoricoEmissao.value(data.value)
      }
    }

    const toggleStatus = () => {
      statusButton.value = !statusButton.value
      controleLiberacao.value(data.value);
    }

    const reenviarEmailHandler = () => {
      if (reenviarEmail.value) {
        reenviarEmail.value(data.value)
      }
    }

    const visualizarIngressoHandler = () => {
      if (visualizarIngresso.value) {
        visualizarIngresso.value(data.value)
      }
    }

    const editarPromoter = () => {
      if (openModalEditarPromoter.value) {
        openModalEditarPromoter.value(data.value)
      }
    }

    const editarPromoterDisponivel = () => {
      if (openModalEditarPromoterDisponivel.value) {
        openModalEditarPromoterDisponivel.value(data.value)
      }
    }


    return { formatarMonetarioBRL, editarPromoterDisponivel, editarPromoter, saldo, saldoHabilitado, buscarSaldoPromoter, visualizarIngressoHandler, reenviarEmailHandler, habilitaringressos, statusButton, toggleStatus, ingressosHabilitado, cancelarEmissao, visualizacaoQRCode, visualizacaoEmissao, promoterMovimentacaoGeral, promoterMovimentacao, excluirPromoterPermanente, vincularPromoter, deletarPromoterVinculado, realizarPagamento, type, data }
  }
};
</script>


<style scoped>
.backgroundingressos {
  background-color: #D9D9D9;
  padding: 0px 5px 0px 5px;
  border-radius: 8px;
  color: #5E5E5E;
}

.slider {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 22px;
}

.slider::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: transform 0.4s;
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

input:checked+.slider::before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round::before {
  border-radius: 50%;
}

.slider {
  background-color: #ccc;
  transition: background-color 0.4s;
}

input:checked+.slider {
  background-color: #E95D88;
}
</style>