<template>
  <div v-if="store.getters.modalVendaPix.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full lg:h-3/4 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog" id="modal">

      <div class="bg-white rounded-lg h-full w-full overflow-auto">
        <h1 class="text-center mt-4 subtitle2 text-SC_Azul"> Deseja prosseguir com a(s) seguinte(s) emissão(ões)?</h1>

        <div class="p-4">
          <div class="flex flex-col gap-5 justify-center items-center mb-3" style="color: #8E8E8E;">
            <!--<img class="img" src="../../assets/images/qrCodeTeste.png" />-->
            <div class="flex flex-col gap-2 items-center">
              <p class="font-semibold my-2" style="font-size: 18px;"> Total: {{ formatarMonetarioBRL(valorTotal) }}</p>
              <p class="text-SC_Azul font-semibold text-center" v-for="(produto, indexProduto) in produtos"
                :key="indexProduto" style="font-size: 13px;">
                {{ produto.produto.label }} - {{ produto.quantidadeTotal }} uni - {{
                  formatarMonetarioBRL(produto.valorTotalProduto) }}
              </p>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-2 w-full" v-for="(emissao, indexEmissao) in emissaoV" :key="indexEmissao">
            <div class="" v-for="(comprador, indexComprador) in emissao.comprador" :key="indexComprador">
              <div class="flex flex-col resumoEmissao mt-5 p-2 gap-3 w-full text-wrap break-all">

                <div class="flex justify-center items-center w-full">
                  <img class="img w-full" :src="`https://santocartao-files.s3.amazonaws.com/event-pictures/${eventoID}.jpg`" />
                </div>

                <div class="flex flex-col w-full">
                  <p class="font-semibold" style="font-size: 18px;"> {{ comprador.nome }} </p>
                  <p class="font-semibold" style="font-size: 15px;"> {{ comprador.email }} </p>
                  <p class="font-semibold" style="font-size: 15px;"> {{ comprador.documento }} </p>
                  <p class="font-normal" style="font-size: 17px;"> {{ emissao.produto.label }}</p>
                  <p class="font-normal" style="font-size: 17px;"> <span>{{ comprador.quantidade }}</span> uni
                    <span> {{ formatarMonetarioBRL(parseFloat(emissao.produto.valor) * comprador.quantidade) }} </span>
                  </p>
                </div>
              </div>

            </div>


          </div>

        </div>

      </div>
      <div class="flex justify-between mt-2">
        <button class="botaoFechar" @click="close">Fechar</button>
        <button  :class="{ 'botaoDesabilitado': Number(valorTotal) === 0 }" class="botaoConfirmar" @click="confirmar" :disabled="Number(valorTotal) === 0">Confirmar</button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, watch, inject } from 'vue';
import moment from "moment-timezone";
import { formatarMonetarioBRL } from "@/services/formater";

export default {
  setup() {
    const store = useStore();
    const emissaoV = ref([])
    const eventoInfo = inject("evento");
    const eventoID = eventoInfo.getEventoID()

    const valorTotal = ref(0)
    const produtos = ref([])

    const close = () => {
      produtos.value = []
      emissaoV.value = []
      valorTotal.value = 0
      store.commit("modalVendaPix", { display: false, emissao: {} });
    };


    watch(
      () => [store.getters.modalVendaPix.display],
      () => {
        if (store.getters.modalVendaPix.display) {
          let quantidadeTotal = 0
          let valorTotalProduto = 0

          emissaoV.value = store.getters.modalVendaPix.emissao
          emissaoV.value.map((item) => {
            item.comprador.map((compra) => {
              quantidadeTotal += parseInt(compra.quantidade)
              valorTotalProduto += parseFloat(compra.quantidade * item.produto.valor)
              valorTotal.value += parseFloat(compra.quantidade * item.produto.valor)
            })
            produtos.value.push({ produto: item.produto, quantidadeTotal: quantidadeTotal, valorTotalProduto: valorTotalProduto });
            quantidadeTotal = 0
            valorTotalProduto = 0
          })


        } else {
          emissaoV.value = []
        }
      })

    const confirmar = async () => {
      store.getters.modalVendaPix.callbackOK({ emissao: emissaoV.value, valorTotal: valorTotal.value });
      produtos.value = []
      emissaoV.value = []
      valorTotal.value = 0
    };



    return { store, close, emissaoV, eventoID, confirmar, formatarMonetarioBRL, produtos, valorTotal };
  },
}
</script>

<style scoped>
.botaoFechar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoFechar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid #E95D88;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: #E95D88;
  color: white;
}

.botaoConfirmar:hover {
  background-color: #b94a6b;
  color: white;
}

.campoDados {
  color: #5E5E5E;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  padding: 3px 0px 3px 15px;
}

.resumoEmissao {
  border: 1px solid #E4E3E3;
  background-color: #FBFCFD;
  color: #8E8E8E;
}

.img {
  /*width: 210px;*/
  height: 210px;
}
</style>