<template>
  <div
    v-if="store.getters.modalPagamento.display"
    x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0"
    x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150"
    x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
  >
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full h-5/6 px-4 py-3 bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-2xl"
      role="dialog" id="modal">
      <!-- Remove header if you don't want a close icon. Use modal body to place modal tile. -->
      <header class="flex justify-center items-center relative">
        <h1 class="font-semibold text-xl text-SC_Azul">
          Lançar pagamento para {{ store.getters.modalPagamento.promoter.promoter }}
        </h1>
        <button @click="close"
          class="inline-flex items-center absolute right-0 justify-self-end w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
          aria-label="close">
          <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" role="img" aria-hidden="true">
            <path
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd" fill-rule="evenodd"></path>
          </svg>
        </button>
      </header>
      <!-- Modal body -->
      <div class="mt-4 mb-6 h-5/6 overflow-y-auto bg-SC_Light_Gray p-4 rounded-md">
        <div :class="`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-3 mb-6'}`">
          <Input
            type="money"
            placeholder="Valor"
            id="valor"
            v-model="data.valor"
          />
          <Input
            type="money"
            placeholder="Saldo"
            id="saldo"
            v-model="data.saldo"
          />
          <Input
            type="number"
            placeholder="Quantidade"
            id="quantidade"
            v-model="data.quantidade"
          />
        </div>
        <div :class="`grid ${isMobile ? 'grid-cols-1' : 'grid-cols-2 mb-6'}`">
          <Input
            type="select"
            placeholder="Selecione o status"
            id="status"
            v-model="data.id_status"
            :data="[
              { label: 'Ativo', value: 1 },
              { label: 'Cancelado', value: 0 }
            ]"
          />
          <Input
            type="date"
            placeholder="Data"
            id="data"
            v-model="data.data"
          />
        </div>
        <div :class="`grid grid-cols-1 ${isMobile ? 'mb-3' : 'mb-6'} gap-3`">
          <Input
            type="default"
            placeholder="Descrição"
            id="descricao"
            v-model="data.description"
          />
        </div>

        <div :class="`flex items-center justify-between ${isMobile && 'mb-8'}`">
          <Button @click="close" color="red-500" text="Cancelar" outline />
          <Button @click="lancarPagamento" color="SC_Green" text="Registrar pagamento" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, watch } from 'vue';
import Input from '../../components/Elements/Input.vue';
import Button from '../../components/Elements/Button.vue';
import { isEmptyPagamento } from '../../services/validate';
import { POST } from '../../services/api';
import account from '../../services/account';

export default {
  name: 'ModalPagamento',

  data() {
    return {
      isMobile: false,
    };
  },

  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },

  components: {
    Input,
    Button
  },

  setup() {
    const store = useStore();
    const data = ref({
      valor: null,
      description: "",
      saldo: null,
      quantidade: null,
      data: "",
      id_status: null,
    });

    const close = () => {
      store.commit("modalPagamento", { display: false, dados_pagamento: {} , promoter: {} });
    };

    const lancarPagamento = async () => {
      try {
        store.commit('loading', true);
        const payload = {
          ...data.value,
          promoters_usuario_id: store.getters.modalPagamento.promoter.promoter_id,
          usuario_id: account.getInfo().id,
          evento_id: store.getters.evento.id,
        };
        const validate = isEmptyPagamento(payload);
        if (!validate) {
          await POST(`promoter_movements/create_payment`, payload);
          showAlert("Sucesso", "Pagamento criado com sucesso", "success");
          close();
        } else {
          showAlert("Aviso", validate, "warning");
        }
      } catch (error) {
        showAlert("Erro", error, "danger");
      } finally {
        store.commit('loading', false);
      }
    };

    const showAlert = (title, message, type) => {
      store.commit('alert', {
        display: true,
        title: title,
        message: message,
        type: type
      });
      setTimeout(() => {
        store.commit('alert', {
          display: false,
        });
      }, 6000);
    };

    watch(
      () => [store.getters.modalPagamento.display],
      () => {
        if (store.getters.modalPagamento.display) {
          data.value = { ...store.getters.modalPagamento.dados_pagamento };
        } else {
          data.value = {
            valor: null,
            description: "",
            saldo: null,
            quantidade: null,
            data: "",
            id_status: null,
          };
        }
      })

    return { store, close, lancarPagamento, data };
  },
}
</script>