<template>
  <div class="overlay min-h-screen w-screen fixed top-0 right-0 flex items-center justify-end"
    v-if="store.getters.modalRetirada.display" @click.self="setarSairModal(true)">
    <SolicitacaoConcluida :retirada="retirada" :valor="toRetire" :closeFunction="finishedSendRetirada" />
    <div class="modal h-screen relative top-0 right-0 flex flex-col items-center justify-start" :class="{
      wFull: isMobile,
      wSmall: !isMobile,
    }">
      <div class="flex items-end justify-end w-full">
        <fa icon="xmark" class="hover:text-primary cursor-pointer" aria-label="Voltar" @click="setarSairModal(true)" />
      </div>
      <div class="flex w-full">
        <i class="gg-chevron-down hover:text-primary cursor-pointer" aria-label="Voltar" @click="sairRetirada"
          v-if="view === 1"></i>
        <i class="gg-chevron-down hover:text-primary cursor-pointer" aria-label="Voltar" @click="voltar"
          v-if="view === 2"></i>
        <p class="text-SC_Azul font-bold text-SC_Text ml-3">
          Solicitar Retirada
        </p>
      </div>
      <div class="flex w-full mt-2 gap-1">
        <div class="flex-1 h-1 rounded-sm" :class="{
          'bg-SC_Roxo': view == 1,
          notMarked: view == 2 || view == 3,
        }"></div>
        <div class="flex-1 h-1 rounded-sm" :class="{
          'bg-SC_Roxo': view == 2 || view == 3,
          notMarked: view == 1,
        }"></div>
      </div>

      <template v-if="view === 1">
        <div class="bg-white flex flex-col p-5 w-full mt-3 rounded-md">
          <p class="text-SC_Gray text-SC_SmallText font-bold">
            Valor disponível
          </p>
          <p class="text-SC_Title font-bold text-SC_Azul">
            {{ available["formatted"] }}
          </p>
        </div>

        <div class="bg-white flex flex-col p-5 w-full mt-3 rounded-md gap-2">
          <p class="text-SC_Gray text-SC_SmallText font-bold">
            Valor da retirada
          </p>
          <Input type="small" placeholder="R$ 0,00" v-model="toRetire" id="inputMoney" class="inputMoney" />
          <p class="text-SC_Gray text-SC_SmallText">
            Saldo após a retirada: {{ afterRetire }}
          </p>
        </div>

        <div class="bg-white flex flex-col p-5 w-full mt-2 rounded-md">
          <p class="text-SC_Gray text-SC_Text font-bold">Enviar para</p>
          <div class="flex flex-col mt-3 mb-2 gap-2" v-if="fav.data.length > 0">
            <p class="text-SC_Gray text-SC_SmallText">Favoritos</p>
            <button
              class="bg-white rounded-md border-2 px-5 py-2 text-SC_SmallText hover:bg-SC_Light_Gray hover:border-SC_Light_Gray cursor-pointer w-full whitespace-nowrap overflow-hidden overflow-ellipsis justify-start"
              v-for="(f, index) in fav.data" :key="index" @click="selectFav(f)" :class="{
                whoSelected: retirada.to['cpf_cnpj'] == f['cpf_cnpj'],
              }">
              {{ f.apelido || f.nome_titular }}
            </button>
          </div>
          <div class="flex flex-col mt-3" v-if="flag">
            <p class="text-SC_Gray text-SC_SmallText">
              Transferir para novo contato
            </p>
            <div class="flex items-center justify-start gap-2 mt-2">
              <!-- <button
                class="rounded-md border-2 px-5 py-2 text-SC_SmallText hover:bg-SC_Light_Gray tex-black cursor-pointer typeBtn"
                :class="{
                  'bg-SC_Azul text-white border-SC_Azul': addNew.type == 'pix',
                }"
                @click="addNew.type = 'pix'"
              >
                PIX
              </button> -->
              <!-- <button
                class="rounded-md border-2 px-5 py-2 text-SC_SmallText cursor-pointer text-black"
                :class="{
                  'bg-SC_Azul text-white border-SC_Azul': addNew.type == 'ted',
                }"
                @click="addNew.type = 'ted'"
              >
                TED
              </button> -->
            </div>

            <div class="mt-4 flex flex-col gap-4">
              <Input placeholder="" type="small" label="Apelido (opcional)" v-model="addNew.data.apelido" />

              <!--<Input placeholder="" type="small" label="Nome do Titular" v-model="addNew.data.nome_titular"
                v-if="addNew.type == 'pix'" />-->

              <!-- tipo PIX -->
              <Input placeholder="" type="small-select" label="Tipo de chave PIX" v-model="addNew.data.tipo_chave_pix"
                v-if="addNew.type == 'pix'" :data="tipoChave" />

              <!-- tipo TED -->

              <label class="block relative">
                <span class="text-gray-700 bg-white px-3 -top-2 left-4 absolute"
                  style="z-index: 999; font-size: 12px;">Banco</span>
                <Multiselect ref="multiselect" deselect-label="Pressione enter para selecionar"
                  select-label="Pressione enter para selecionar" v-model="addNew.data.id_banco" :options="banks"
                  placeholder="" label="label" :custom-label="label" :allow-empty="false" :searchable="true"
                  selectedLabel="" @select="toggle" v-if="addNew.type == 'ted'">
                  <template #noResult>
                    Nenhum banco encontrado!!
                  </template>
                </Multiselect>
              </label>

              <!--<Input type="small-select" label="Banco" v-model="addNew.data.id_banco" :data="banks"
                v-if="addNew.type == 'ted'" />-->


              <Input placeholder="" type="small" label="Agência" v-model="addNew.data.agencia"
                v-if="addNew.type == 'ted'" :length="4" />
              <div class="flex items-center gap-1 w-full">
                <Input placeholder="" type="small" label="Conta" v-model="addNew.data.numero_conta"
                  v-if="addNew.type == 'ted'" :class="{
                    'w-1/2': !isMobile,
                    'w-full': isMobile,
                  }" />
                <Input placeholder="" type="small" label="Dígito" v-model="addNew.data.numero_conta_digito"
                  v-if="addNew.type == 'ted'" :length="1" :class="{
                    'w-1/2': !isMobile,
                    'w-full': isMobile,
                  }" />
              </div>

              <!--<Input type="small-select" label="Tipo de Conta" v-model="addNew.data.tipo_conta" :data="tipoConta"
                v-if="addNew.type == 'ted'" />-->
              <Input placeholder="" type="small" label="Nome do Titular" v-model="addNew.data.nome_titular"
                v-if="addNew.type == 'ted'" />
              <Input type="small-select" label="Tipo de Pessoa" v-model="addNew.data.tipo_pessoa" :data="tipoPessoa"
                v-if="addNew.type == 'ted'" />
              <Input placeholder="" :type="addNew.data.tipo_pessoa === 'j' ? 'cnpj' : 'small-cpf'"
                :label="addNew.data.tipo_pessoa === 'j' ? 'CNPJ' : 'CPF'" v-model="addNew.data.cpf_cnpj" id="cpf_cnpj"
                small="true" />


              <Input placeholder="" type="small" label="Tipo de chave PIX (opcional)" v-model="addNew.data.tipo_chave_pix" />
              <Input placeholder="" type="small" label="Chave PIX (opcional)" v-model="addNew.data.chave_pix" />

              <Input placeholder="" type="small" label="Descrição da retirada (opcional)"
              v-model="addNew.data.descricao" />
            </div>
          </div>

          <div class="flex items-center justify-start gap-1 mt-3" v-if="flag">
            <input type="checkbox" class="cursor-pointer" :value="fav.save" :id="fav.save" v-model="fav.save" />
            <label class="text-SC_SmallText text-SC_Gray" :for="retirada.saveFav">
              Salvar este contato nos favoritos
            </label>
          </div>

          <button class="bg-SC_Roxo text-white rounded-md px-5 py-2 mt-4 cursor-pointer continue" id="continueBtn"
            @click="resume">
            Continuar para o resumo
          </button>
        </div>
      </template>

      <template v-else-if="view === 2">
        <div class="bg-white flex flex-col p-5 w-full mt-4 rounded-md">
          <p class="text-SC_Gray text-SC_SmallText font-bold">Valor</p>
          <p class="text-SC_Title font-bold text-SC_Azul">
            {{ toRetire }}
          </p>

          <p class="text-SC_Gray text-SC_SmallText font-bold mt-4">Para</p>
          <p class="text-SC_Title font-bold text-SC_Azul">
            {{ retirada.to.apelido || retirada.to.nome_titular }}
          </p>

          <div class="fle flex-col mt-3 gap-2">
            <div
              class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3">
              <p class="text-SC_Gray text-SC_SmallText">Nome do Titular</p>
              <p class="text-SC_SmallText text-SC_Gray font-semibold">
                {{ retirada.to["nome_titular"] }}
              </p>
            </div>
            <div
              class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3"
              v-if="retirada.to.apelido">
              <p class="text-SC_Gray text-SC_SmallText">Apelido</p>
              <p class="text-SC_SmallText text-SC_Gray font-semibold">
                {{ retirada.to["apelido"] }}
              </p>
            </div>
            <div
              class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3">
              <p class="text-SC_Gray text-SC_SmallText">CPF/CNPJ</p>
              <p class="text-SC_SmallText text-SC_Gray font-semibold">
                {{ retirada.to["cpf_cnpj"] }}
              </p>
            </div>
            <div
              class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3">
              <p class="text-SC_Gray text-SC_SmallText">Instituição</p>
              <p class="text-SC_SmallText text-SC_Gray font-semibold">
                {{ retirada.to.banco }}
              </p>
            </div>
            <div
              class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3">
              <p class="text-SC_Gray text-SC_SmallText">Agência</p>
              <p class="text-SC_SmallText text-SC_Gray font-semibold">
                {{ retirada.to.agencia }}
              </p>
            </div>
            <div
              class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3">
              <p class="text-SC_Gray text-SC_SmallText">Conta</p>
              <p class="text-SC_SmallText text-SC_Gray font-semibold">
                {{ retirada.to.numero_conta }} -
                {{ retirada.to.numero_conta_digito }}
              </p>
            </div>
            <!--<div
              class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3">
              <p class="text-SC_Gray text-SC_SmallText">Tipo de Conta</p>
              <p class="text-SC_SmallText text-SC_Gray font-semibold">
                {{
                  retirada.to.tipo_conta == "cc"
                    ? "Conta Corrente"
                    : "Conta Poupança"
                }}
              </p>
            </div>-->
            <div
              class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3"
              v-if="retirada.to.tipo_pessoa == 'j'">
              <p class="text-SC_Gray text-SC_SmallText">CNPJ</p>
              <p class="text-SC_SmallText text-SC_Gray font-semibold">
                {{ retirada.to.cpf_cnpj }}
              </p>
            </div>
            <div
              class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3"
              v-else>
              <p class="text-SC_Gray text-SC_SmallText">CPF</p>
              <p class="text-SC_SmallText text-SC_Gray font-semibold">
                {{ retirada.to.cpf_cnpj }}
              </p>
            </div>
            <div
              class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3"
              v-if="retirada.to.tipo_chave_pix">
              <p class="text-SC_Gray text-SC_SmallText">Tipo Chave PIX</p>
              <p class="text-SC_SmallText text-SC_Gray font-semibold">
                {{ retirada.to.tipo_chave_pix }}
              </p>
            </div>
            <div
              class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3"
              v-if="retirada.to.chave_pix">
              <p class="text-SC_Gray text-SC_SmallText">Chave PIX</p>
              <p class="text-SC_SmallText text-SC_Gray font-semibold">
                {{ retirada.to.chave_pix }}
              </p>
            </div>
            <div
              class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3"
              v-if="retirada.to.descricao">
              <p class="text-SC_Gray text-SC_SmallText">Descrição</p>
              <p class="text-SC_SmallText text-SC_Gray font-semibold">
                {{ retirada.to.descricao }}
              </p>
            </div>
          </div>

          <button class="bg-SC_Roxo text-white rounded-md px-5 py-2 mt-4 cursor-pointer continue" id="continueBtn"
            @click="sendMail">
            Solicitar retirada
          </button>
        </div>
      </template>

      <template v-else-if="view === 3">
        <div class="bg-white flex flex-col p-5 w-full mt-4 rounded-md">
          <p class="text-SC_Title font-bold text-SC_Azul">
            Solicitação concluída
          </p>

          <p class="text-SC_Gray text-SC_SmallText mt-2">
            O prazo para a conclusão desta solicitação é de até <b>3 dias úteisasdsad.</b> As transferências são realizadas
            entre <b>15h e 16h</b>.
          </p>

          <div class="bg-white flex flex-col p-5 w-full mt-4 rounded-md">
            <p class="text-SC_Gray text-SC_SmallText font-bold">Valor</p>
            <p class="text-SC_Gray text-SC_Text font-bold">
              {{ toRetire }}
            </p>

            <p class="text-SC_Gray text-SC_SmallText font-bold mt-4">Para</p>
            <p class="text-SC_Gray text-SC_Text font-bold">
              {{ retirada.to.apelido || retirada.to.nome_titular }}
            </p>

            <div class="flex flex-col mt-3 gap-2">
              <div
                class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3">
                <p class="text-SC_Gray text-SC_SmallText">Nome do Titular</p>
                <p class="text-SC_SmallText text-SC_Gray font-semibold">
                  {{ retirada.to["nome_titular"] }}
                </p>
              </div>
              <div
                class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3">
                <p class="text-SC_Gray text-SC_SmallText">CPF/CNPJ</p>
                <p class="text-SC_SmallText text-SC_Gray font-semibold">
                  {{ retirada.to["cpf_cnpj"] }}
                </p>
              </div>
              <div
                class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3">
                <p class="text-SC_Gray text-SC_SmallText">Instituição</p>
                <p class="text-SC_SmallText text-SC_Gray font-semibold">
                  {{ retirada.to.banco }}
                </p>
              </div>
              <div
                class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3">
                <p class="text-SC_Gray text-SC_SmallText">Agência</p>
                <p class="text-SC_SmallText text-SC_Gray font-semibold">
                  {{ retirada.to.agencia }}
                </p>
              </div>
              <div
                class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3">
                <p class="text-SC_Gray text-SC_SmallText">Conta</p>
                <p class="text-SC_SmallText text-SC_Gray font-semibold">
                  {{ retirada.to.numero_conta }} -
                  {{ retirada.to.numero_conta_digito }}
                </p>
              </div>
              <!--<div
                class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3">
                <p class="text-SC_Gray text-SC_SmallText">Tipo de Conta</p>
                <p class="text-SC_SmallText text-SC_Gray font-semibold">
                  {{
                    retirada.to.tipo_conta == "cc"
                      ? "Conta Corrente"
                      : "Conta Poupança"
                  }}
                </p>
              </div>-->
              <div
                class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3"
                v-if="retirada.to.tipo_pessoa == 'j'">
                <p class="text-SC_Gray text-SC_SmallText">CNPJ</p>
                <p class="text-SC_SmallText text-SC_Gray font-semibold">
                  {{ retirada.to.cpf_cnpj }}
                </p>
              </div>
              <div
                class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3"
                v-else>
                <p class="text-SC_Gray text-SC_SmallText">CPF</p>
                <p class="text-SC_SmallText text-SC_Gray font-semibold">
                  {{ retirada.to.cpf_cnpj }}
                </p>
              </div>
              <div
                class="flex items-center justify-between overflow-x-hidden whitespace-nowrap overflow-hidden overflow-ellipsis gap-3">
                <p class="text-SC_Gray text-SC_SmallText">Chave PIX</p>
                <p class="text-SC_SmallText text-SC_Gray font-semibold">
                  {{ retirada.to.chave_pix}}
                </p>
              </div>
            </div>

            <button class="bg-SC_Roxo text-white rounded-md px-5 py-2 mt-4 cursor-pointer continue" id="continueBtn"
              @click="closeAll">
              Fechar
            </button>
          </div>
        </div>
      </template>
    </div>

    <div v-if="sairModalRetirada" x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0"
      class="absolute inset-0 flex bg-black bg-opacity-50 items-center justify-center z-99">
      <div x-transition:enter="transition ease-out duration-150"
        x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
        x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
        x-transition:leave-end="opacity-0  transform translate-y-1/2"
        class="modalSairRetirada px-6 py-4 bg-white rounded-t-lg dark:bg-gray-800 rounded-lg sm:m-4 sm:w-auto flex flex-col justify-evenly items-center z-60 gap-5"
        role="dialog">
        <h1 class="text-SC_SmallSubtitle font-bold">
          Tem certeza que deseja sair?
        </h1>
        <div class="flex items-center justify-evenly w-full gap-3">
          <button class="btn text-center flex items-center justify-center rounded-md bg-SC_Roxo"
            @click="setarSairModal(false)">
            Não
          </button>
          <button class="btn bg-SC_Gray text-center flex items-center justify-center rounded-md" @click="sairRetirada">
            Sim
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { useStore } from "vuex";
import { inject, ref, watch, onMounted } from "vue";
import { numberToCnpj, numberToCpf, toMoney } from "@/services/formater";
//import { mask } from "vue-the-mask";
import { cnpj, cpf } from "cpf-cnpj-validator"
import Input from "./Input.vue";
import { GET, POST } from "@/services/api";
import { faGlassCheers } from "@fortawesome/free-solid-svg-icons";
import SolicitacaoConcluida from "./SolicitacaoConcluida.vue";
import { EventoInfo } from "@/services/geral";
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  name: "SolicitarRetirada",
  components: {
    Input,
    SolicitacaoConcluida,
    Multiselect,
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    toggle() {
      this.$refs.multiselect.$el.focus()
      setTimeout(() => {
        this.$refs.multiselect.$refs.search.blur()
      }, 100)
    },
  },
  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    });
  },
  setup: () => {
    const alert = inject("alert");
    const loader = inject("loading");
    const store = useStore();
    const view = ref(1);
    const disabled = ref(false);
    const flag = ref(true);
    const modal = ref(false);
    const finished = ref(false);
    const available = ref({
      bruto: store.getters.dadosFinanceiro.valorTotal,
      formatted: toMoney(store.getters.dadosFinanceiro.valorTotal),
    });
    const toRetire = ref(null);
    const afterRetire = ref(available.value.formatted);
    const fav = ref({ save: false, data: [] });
    const eventoId = EventoInfo.getEventoID();

    const sairModalRetirada = ref(false)

    const voltar = () => {
      view.value = 1
    }

    const retirada = ref({
      value: toRetire.value,
      to: {
        id: "",
        id_usuario: "",
        banco: "",
        agencia: "",
        tipo_conta: "",
        numero_conta: "",
        numero_conta_digito: "",
        nome_titular: "",
        cpf_cnpj: "",
        id_banco: "",
        tipo_pessoa: "",
        tipo_chave_pix: "",
        chave_pix: "",
        descricao: "",
      },
    });

    const addNew = ref({
      type: "ted",
      data: {
        id_usuario: "",
        banco: "",
        agencia: "",
        tipo_conta: "",
        numero_conta: "",
        numero_conta_digito: "",
        nome_titular: "",
        cpf_cnpj: "",
        id_banco: "",
        tipo_pessoa: "",
        tipo_chave_pix: "",
        chave_pix: "",
        apelido: "",
      },
    });
    const banks = ref([]);

    const tipoPessoa = ref([
      {
        value: "f",
        label: "Pessoa Física",
      },
      {
        value: "j",
        label: "Pessoa Jurídica",
      },
    ]);

    const tipoConta = ref([
      {
        value: "cc",
        label: "Conta Corrente",
      },
      {
        value: "poupanca",
        label: "Conta Poupança",
      },
    ]);

    const tipoChave = ref([
      {
        value: "cpf",
        label: "CPF",
      },
      {
        value: "cnpj",
        label: "CNPJ",
      },
      {
        value: "email",
        label: "E-mail",
      },
      {
        value: "celular",
        label: "Celular",
      },
      {
        value: "aleatorio",
        label: "Chave aleatória",
      },
    ]);


    const close = () => {
      if (view.value - 1 == 0) {
        modal.value = true;
        setarSairModal(true)
      } else {
        modal.value = false;
        view.value -= 1;
      }
    };

    const add = (value) => {
      if (value == 0) {
        toRetire.value = available.value.bruto;
        return;
      } else if (parseFloat(toRetire.value) + value >= available.value.bruto) {
        toRetire.value = available.value.bruto;
        return;
      } else {
        toRetire.value += value;
      }
    };

    const getBancos = async () => {
      try {
        const response = await GET("banco");
        response.map((r) => {
          banks.value.push({
            value: parseInt(r.id),
            codigo: r.codigo,
            label: `${r.codigo} | ${r.nome}`,
          });
        });

      } catch (error) {
        alert.open("Atenção!", "Não foi possível carregar os bancos", "error");
      }
    };

    const getFavs = async () => {
      const evento = JSON.parse(localStorage.getItem("event_santo_cartao"));
      try {
        const a = await GET(`dadosBancarios/favoritos/${evento.id_usuario}`);
        a.map((f) => {
          fav.value.data.push(f);
        });
      } catch (error) {
        console.error(error);
      }
    };

    const resume = async () => {
      try {
        if (toRetire.value) {
          const splitted = toRetire.value.split("$");
          const formatted = splitted[1].replace(".", "").replace(",", ".");
          retirada.value.value = toRetire.value;
          disabled.value = true
          if (formatted <= 0) {
            alert.open("Atenção!", "Insira um valor válido");
            disabled.value = false;
            return;
          } else if (formatted >= parseFloat(available.value.bruto)) {

            const retiradaValue = retirada.value.value;
            const availableValueBruto = available.value.bruto;


            const retiradaFormatada = parseFloat(retiradaValue.replace('R$', '').replace(/\./g, '').replace(',', '.'));
            const avaibleFormatada = parseFloat(availableValueBruto);

            const resultado = retiradaFormatada - avaibleFormatada;

            retirada.value.value = -resultado;
          }

          if (addNew.value.type !== "fav") {
            /* lógica de salvar favoritos */
            const required = [
              { label: "Nome do Titular", value: "nome_titular" },
              { label: "Banco", value: "id_banco" },
              { label: "Agência", value: "agencia" },
              { label: "Número da Conta", value: "numero_conta" },
              { label: "Dígito", value: "numero_conta_digito" },
              //{ label: "Tipo de Conta", value: "tipo_conta" },
              { label: "Tipo de Pessoa", value: "tipo_pessoa" },
              { label: "CPF/CNPJ", value: "cpf_cnpj" },
            ];

            const a = required.filter((r) => {
              return addNew.value.data[r.value] === "";
            });
            a.forEach((r) => {
              alert.open("Atenção!", `Preencha o campo ${r.label}`, "error");
              disabled.value = false;
            });

            if (!disabled.value) {
              return null
            }

            // validar cpf e cnpj
            if (addNew.value.data.tipo_pessoa === 'j' && !cnpj.isValid(addNew.value.data.cpf_cnpj)) {
              alert.open("Atenção!", `Prencha um CNPJ válido`, "error");
              return

            } else if (addNew.value.data.tipo_pessoa === 'f' && !cpf.isValid(addNew.value.data.cpf_cnpj)) {
              alert.open("Atenção!", `Prencha um CPF válido`, "error");
              return
            }

            const banco = addNew.value.data["id_banco"].label.split('|')

            addNew.value.data["banco"] = banco[1].replace(" ", "");
            retirada.value.to = addNew.value.data;


            view.value = 2;
          } else if (addNew.value.type === "fav") {
            /* lógica usar favoritos */
            view.value = 2;
          }
        } else {
          alert.open("Atenção!", "Preencha o valor da retirada", "error");
        }
      } catch (error) {
        console.log(error)
        alert.open("Atenção!", "Preencha os campos", "error");
      }
    };

    const selectFav = (f) => {
      if (retirada.value.to.banco) {
        retirada.value.to = {};
        flag.value = true;
        addNew.value.type = "ted";
      } else {
        addNew.value.type = "fav";
        retirada.value.to = f;
        retirada.value.to.agencia = parseInt(f.agencia);
        flag.value = false;
      }
    };

    function finishedSendRetirada() {
      toRetire.value = null;
      flag.value = true;
      addNew.value.data = {
        id_usuario: "",
        banco: "",
        agencia: "",
        tipo_conta: "",
        numero_conta: "",
        numero_conta_digito: "",
        nome_titular: "",
        cpf_cnpj: "",
        id_banco: "",
        tipo_pessoa: "",
        tipo_chave_pix: "",
        chave_pix: "",
        apelido: "",
      };
      addNew.value.type = "ted";
      retirada.value.to = {
        id: "",
        id_usuario: "",
        banco: "",
        agencia: "",
        tipo_conta: "",
        numero_conta: "",
        numero_conta_digito: "",
        nome_titular: "",
        cpf_cnpj: "",
        id_banco: "",
        tipo_pessoa: "",
        chave_pix: "",
        tipo_chave_pix: "",
      };
      view.value = 1;
    }


    const sendMail = async () => {
      loader.open();
      try {
        let data = {};
        const evento = JSON.parse(
          localStorage.getItem("event_santo_cartao")
        ).nome;
        data = {
          evento_id: eventoId,
          evento: evento,
          value: toRetire.value,
          nome_titular: retirada.value.to.nome_titular,
          banco: retirada.value.to.banco,
          agencia: retirada.value.to.agencia,
          tipo_conta: retirada.value.to.tipo_conta,
          numero_conta: `${retirada.value.to.numero_conta}-${retirada.value.to.numero_conta_digito}`,
          cpf_cnpj: retirada.value.to.cpf_cnpj,
          tipo_pessoa: retirada.value.to.tipo_pessoa,
          tipo_chave_pix: retirada.value.to.tipo_chave_pix,
          chave_pix: retirada.value.to.chave_pix,
          descricao: retirada.value.to.descricao,
        };

        await POST("email/retirada", data);
        if (fav.value.save) {
          await POST("dadosBancarios/favoritos/save", { ...addNew.value.data });
        }

        store.commit("modalRetiradaConcluida", { display: true });

        loader.close();
      } catch (error) {
        loader.close();
        console.log(error);
      }
    };

    const closeAll = () => {
      store.commit("modalRetirada", { display: false });
      toRetire.value = null;
      flag.value = true;
      addNew.value.data = {
        id_usuario: "",
        banco: "",
        agencia: "",
        tipo_conta: "",
        numero_conta: "",
        numero_conta_digito: "",
        nome_titular: "",
        cpf_cnpj: "",
        id_banco: "",
        tipo_pessoa: "",
        tipo_chave_pix: "",
        chave_pix: "",
        apelido: "",
      };
      addNew.value.type = "ted";
      retirada.value.to = {
        id: "",
        id_usuario: "",
        banco: "",
        agencia: "",
        tipo_conta: "",
        numero_conta: "",
        numero_conta_digito: "",
        nome_titular: "",
        cpf_cnpj: "",
        id_banco: "",
        tipo_pessoa: "",
        chave_pix: "",
        tipo_chave_pix: "",
      };
      view.value = 1;
    };

    watch(
      () => store.getters.modalRetirada.display,
      (newValue, oldValue) => {
        if (newValue) {
          loader.open();
          store.commit("modalRetiradaConcluida", { display: false });
          try {
            available.value = {
              bruto: store.getters.dadosFinanceiro.valorTotal,
              formatted: toMoney(store.getters.dadosFinanceiro.valorTotal),
            };
            afterRetire.value = toMoney(available.value.bruto);
            getBancos();
            getFavs();
            addNew.value.data["id_usuario"] = JSON.parse(
              localStorage.getItem("event_santo_cartao")
            ).id_usuario;
            loader.close();
          } catch (error) {
            alert.open(
              "Atenção!",
              "Não foi possível carregar os dados financeiros",
              "error"
            );
          }
        } else {
          fav.value.data = [];
        }
      }
    );

    const maskCurrency = (valor, locale = "pt-BR", currency = "BRL") => {
      return new Intl.NumberFormat(locale, {
        style: "currency",
        currency,
      }).format(valor);
    };

    watch(toRetire, (value) => {
      // const temp = formatar(value);
      // const f = parseFloat(
      //   value.replace(".", "").replace(",", ".").replace("R$ ", "")
      // );

      if (value) {

        const f = value
          .split("")
          .filter((v) => /\d/.test(v))
          .join("")
          .padStart(3, "0");
        const digits = f.slice(0, -2) + "." + f.slice(-2);
        const temp = maskCurrency(digits);

        toRetire.value = temp;
        afterRetire.value = toMoney(available.value.bruto - digits);

      }


    });

    const format = (value) => {
      const temp = value.replace(/[\D]+/g, "");
      let a = "";
      if (temp.length === 11) {
        const regex = /(\d{3})(\d{3})(\d{3})(\d{2})/g;
        a = temp.replace(regex, "$1.$2.$3-$4");
      } else if (temp.length === 14) {
        const regex = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g;
        a = temp.replace(regex, "$1.$2.$3/$4-$5");
      } else if (temp.length > 14) {
        const regex = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g;
        a = temp.replace(regex, "$1.$2.$3/$4-$5");
        addNew.value.data.cpf_cnpj = "" + temp;
        return;
      } else {
        a = temp;
      }
      return a;
    };

    const formatcnpj = (value) => {
      const temp = value.replace(/[\D]+/g, "").substring(0, 14);
      let a = "";
      const regex = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})([0,13])/g;
      a = temp.replace(regex, "$1.$2.$3/$4-$5");
      return a;
    };

    watch(
      () => addNew.value.data.cpf_cnpj,
      (newValue, oldValue) => {
        if (newValue) {
          const temp = newValue.replace(/[\D]+/g, "");
          if (temp.length <= 14) {
            const a = format(newValue);
            addNew.value.data.cpf_cnpj = "" + a;
          } else {
            const a = formatcnpj(newValue);
            addNew.value.data.cpf_cnpj = "" + a.substring(0, 18);
          }
        } else {
          return;
        }
      }
    );

    watch(() => addNew.value.data.tipo_pessoa, () => {
      addNew.value.data.cpf_cnpj = ''
    })

    const tiraLetras = (value) => {
      const temp = value.replace(/[a-zA-Z]+/g, "");
      return temp;
    };

    const tiraNumeros = (value) => {
      const temp = value.replace(/[\d]+/g, "");
      return temp;
    };

    watch(
      () => addNew.value.data.numero_conta,
      (newValue, oldValue) => {
        if (newValue) {
          const temp = tiraLetras(newValue);
          addNew.value.data.numero_conta = "" + temp;
        }
      }
    );

    watch(
      () => addNew.value.data.nome_titular,
      (newValue, oldValue) => {
        if (newValue) {
          const temp = tiraNumeros(newValue);
          addNew.value.data.nome_titular = "" + temp;
        }
      }
    );

    watch(
      () => addNew.value.data.agencia,
      (newValue, oldValue) => {
        if (newValue) {
          const temp = tiraLetras(newValue);
          addNew.value.data.agencia = "" + temp;
        }
      }
    );

    function setarSairModal(value) {
      sairModalRetirada.value = value;
    }


    function sairRetirada() {
      if (view.value === 1) {
        setarSairModal(false)
        closeAll()
      } else if (view.value === 2) {
        view.value = 1
      }
    }

    return {
      open,
      view,
      available,
      toRetire,
      afterRetire,
      store,
      fav,
      retirada,
      addNew,
      banks,
      tipoPessoa,
      tipoConta,
      tipoChave,
      disabled,
      flag,
      modal,
      finished,
      resume,
      add,
      close,
      selectFav,
      toMoney,
      sendMail,
      closeAll,
      sairModalRetirada,
      setarSairModal,
      sairRetirada,
      finishedSendRetirada,
      voltar,
    };
  },
};
</script>

<style>
.multiselect__tag {
  background: #7d1d54;
}

.multiselect__option--highlight {
  background: #7d1d54;
}

.multiselect__option--highlight:after {
  background: #7d1d54;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #e95d88;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #7d1d54;
}
</style>


<style lang="scss" scoped>
.overlay {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(5.423015594482422px);
  z-index: 45;

  .wSmall {
    width: 34%;
  }

  .wFull {
    width: 100%;
  }

  .modal {
    background: #f1f2f6;
    z-index: 9999;
    display: flex;
    padding: 30px 28px;
    overflow-x: scroll;

    .notMarked {
      background-color: #d8dae2;
    }

    .inputMoney {
      &::before {
        content: "R$ ";
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: #7c1d55;
      }
    }
  }

  .gg-chevron-down {
    transform: rotate(90deg);
  }

  .addBtn {
    transition: all 0.1s ease-in-out;

    &:focus {
      background-color: #e95d88;
      color: white;
      border-color: #e95d88;
    }
  }

  .typeBtn {
    transition: all 0.1s ease-in-out;

    &:hover {
      background-color: #f1f2f6;
      color: black;
      border-color: #f1f2f6;
    }
  }

  input[type="checkbox"] {
    appearance: none;
    height: 15px;
    width: 15px;
    background: #cecdcd;
    border-radius: 4px;

    &:checked {
      height: 15px;
      width: 15px;
      background: #7c1d55;
      border-radius: 4px;
    }
  }

  .whoSelected {
    background-color: #e95d88;
    color: white;
    border-color: #e95d88;
  }

  .continue {
    &:disabled {
      cursor: not-allowed;
      background: linear-gradient(0deg,
          rgba(255, 255, 255, 0.6) 0%,
          rgba(255, 255, 255, 0.6) 100%),
        #7d1d54;
    }
  }
}

.modalSairRetirada {
  z-index: 99999;
}

.btn {
  height: 30px;
  padding: 4px 8px;
  color: white;
  cursor: pointer;
}
</style>
