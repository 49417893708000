import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0",
  class: "fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
}
const _hoisted_2 = {
  "x-transition:enter": "transition ease-out duration-150",
  "x-transition:enter-start": "opacity-0 transform translate-y-1/2",
  "x-transition:enter-end": "opacity-100",
  "x-transition:leave": "transition ease-in duration-150",
  "x-transition:leave-start": "opacity-100",
  "x-transition:leave-end": "opacity-0  transform translate-y-1/2",
  class: "w-full lg:h-1/2 h-full px-4 pt-4 pb-14 bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl",
  role: "dialog",
  id: "modal"
}
const _hoisted_3 = { class: "bg-white rounded-lg h-full w-full overflow-auto" }
const _hoisted_4 = { class: "flex flex-col justify-center items-center" }
const _hoisted_5 = { class: "title1 text-SC_Azul text-center p-5 mt-3" }
const _hoisted_6 = {
  class: "font-normal",
  style: {"font-size":"22px"}
}
const _hoisted_7 = {
  key: 0,
  class: "subtitle2 font-bold text-SC_Azul my-3"
}
const _hoisted_8 = {
  key: 1,
  class: "subtitle2 font-bold text-SC_Azul my-3"
}
const _hoisted_9 = {
  class: "font-semibold text-center",
  style: {"font-size":"16px"}
}
const _hoisted_10 = {
  class: "font-semibold text-center",
  style: {"font-size":"16px"}
}
const _hoisted_11 = { class: "flex flex-row justify-between mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.store.getters.modalLiberarIngresso.display)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("span", _hoisted_6, [
                  _createTextVNode(" Você realmente deseja " + _toDisplayString($setup.tipoBotao) + " ", 1),
                  ($setup.tipoBotao !== 'Zerar')
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($setup.liberacaoIngresso[1].quantidade), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_8, " os ")),
                  _cache[2] || (_cache[2] = _createTextVNode(" ingresso(s): ")),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ingressos, (ingresso, indexIngressos) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "flex flex-col gap-2 mt-3",
                      key: indexIngressos
                    }, [
                      _createElementVNode("p", _hoisted_9, _toDisplayString(ingresso.label) + " - R$ " + _toDisplayString(ingresso.valor), 1)
                    ]))
                  }), 128)),
                  _cache[3] || (_cache[3] = _createElementVNode("p", { class: "my-3" }, " Para o(s) seguinte(s) promoter(s)? ", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.liberacaoIngresso[0], (promoters, indexPromoters) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "flex flex-col gap-1",
                      key: indexPromoters
                    }, [
                      _createElementVNode("p", _hoisted_10, _toDisplayString(promoters.promoter), 1)
                    ]))
                  }), 128))
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              class: "botaoFechar",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.close && $setup.close(...args)))
            }, "Fechar"),
            _createElementVNode("button", {
              class: "botaoConfirmar",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.confirmar && $setup.confirmar(...args)))
            }, "Confirmar")
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}