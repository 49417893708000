<template>
  <div v-if="store.getters.modalIngressoEmissao.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-50 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full h-5/6 px-6 py-4 bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog" id="modal">
      <!-- Remove header if you don't want a close icon. Use modal body to place modal tile. -->
      <header class="flex justify-end">
        <button @click="close"
          class="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded dark:hover:text-gray-200 hover: hover:text-gray-700"
          aria-label="close">
          <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" role="img" aria-hidden="true">
            <path
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd" fill-rule="evenodd"></path>
          </svg>
        </button>
      </header>
      <!-- Modal body -->
      <div class="mt-4 mb-6 h-5/6 overflow-y-auto">
        <div class="flex justify-center bg-gray-100 h-16 items-center w-[238px]">
          <img src="https://santocartao-files.s3.amazonaws.com/assets_voucher/logo.png" alt="Santo Cartao"
            class="h-10 w-[238px]">
        </div>
        <div class="flex justify-center items-center  my-2">
          <span class="text-xl">Olá, {{ data.nome }}, segue sua compra:</span>
        </div>
        <div v-for="(value, index) in data.venda_produto" :key="index" class="flex flex-col">
          <div class="flex flex-col justify-center items-center" v-for="(qrcodeValue, index2) in value.qrcode" :key="index2">

            <div v-if="qrcodeValue.qrcode" :key="index2" class="flex flex-col">
              <div v-if="index2 > 0" class="flex justify-center my-5">
                <img :src='`${assets}assets_voucher/iconPlus.png`' alt="asdass">
              </div>

              <div class="flex justify-center">
                <img :src='`${assets}assets_voucher/top-ticket.png`' alt="" v-if="index === 0" style="width: 120px;">
              </div>

              <div class="flex flex-col content-center">
                <div class="flex justify-center">
                  <img :src='`${assets}product-pictures/${value.produto.cod_produto}.jpg`' alt="Imagem Ingresso"
                    style="width: 120px; height: 120px;">
                </div>
                <div class="flex flex-col content-center w-56" style="margin: 10px;">
                  <p class="font-semibold text-center p-2 text-base"> <b>
                      {{ value.produto.idEvento.nome }} -
                      {{ dataEvento(value.produto.idEvento) }}
                    </b>
                    <br>
                    {{ value.produto.detalhes }}
                  </p>
                </div>
              </div>
              <div class="flex flex-col content-center" style="gap: 10px; margin-bottom: 10px;">
                <p class="text-center " style="padding:0px; line-height:8px; font-size:12px; font-weight:normal">
                  Número do ingresso
                </p>
                <span class="font-bold text-center text-red-500"
                  style="padding:0px;font-size:20px;font-weight:bold;color:#ed1c24">
                  {{ `${data.transacao}/${qrcodeValue.identificador}` }}
                </span>
                <!-- Caso seja PDV Promoter-->
                <div v-if="data.promotersMovementsVenda" class="w-full" style="">
                  <p class="text-xs text-center uppercase" style="font-size:10px">
                    <span class="font-bold">Promoter {{
                      data.promotersMovementsVenda.promoterMovements.promoterUsuario.nome }}</span><br>
                    <span class="font-medium">
                      {{
                        gerarMascaraCPFOcultado(data.promotersMovementsVenda.promoterMovements.promoterUsuario.promoters.documento)
                      }}
                    </span>
                  </p>
                  <p class="text-xs text-center uppercase " style="font-size:10px"><span class="font-bold">{{
                    data.promotersMovementsVenda.promoterMovements.promotersMovementsDetails.nome }}</span>
                    <br>
                    <span class="font-medium">
                      {{
                        mask(data.promotersMovementsVenda.promoterMovements.promotersMovementsDetails.cpf.replace(/\D/g,
                          ""))
                      }}
                    </span>
                  </p>
                </div>
                <div v-if="!data.promotersMovementsVenda" class="w-full mt-3" style="">
                  <p class="text-xs text-center uppercase font-medium" style="font-size:10px">
                    {{ data.nome }}
                    <br>
                    {{ mask(data.cpf.replace(/\D/g, "")) }}
                  </p>
                </div>
              </div>
              <!-- Caso seja QRCODE e bottom-->
              <div class="flex justify-center">
                <img :src='`${assets}assets_voucher/${qrcodeValue.qrcode}.png`' alt="QRCode">
              </div>

              <div class="pos" id="testes">
                <div class="custom-list-style" v-html="value.produto.regras_produto"></div>
              </div>


              <div class="flex justify-center mt-5">
                <img :src='`${assets}assets_voucher/down-ticket.png`' alt="asdass">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { ref, watch } from 'vue';
import moment from "moment-timezone";
import { gerarMascaraCNPJ, gerarMascaraCPFOcultado, gerarMascaraRG, gerarMascaraCPF } from '../../services/formater';

export default {
  setup() {
    const store = useStore();
    const assets = ref('https://santocartao-files.s3.amazonaws.com/')
    const data = ref()

    const dataEvento = (evento) => {
      const dataEvento = moment(evento.data).tz('America/Sao_Paulo').format('DD/MM/YYYY')
      const dataEventoInit = moment(evento.data_inicio).tz('America/Sao_Paulo').format('DD/MM')
      const dataEventoFim = moment(evento.data_fim).tz('America/Sao_Paulo').format('DD/MM')
      if (dataEventoInit !== dataEventoFim) {
        return `${dataEventoInit} até ${dataEventoFim}`
      }
      return ` ${dataEvento}`
    }


    const close = () => {
      store.commit("modalIngressoEmissao", { display: false, ingresso: {} });
    };

    const mask = (documento) => {




      const documentoPromoterMask = documento.length === 11 ? 0 : (documento.length === 14 ? 1 : (documento.length === 9 ? 2 : 3));
      const documentoPromoterLabel = documento.length === 11 ? 'CPF' : (documento.length === 14 ? 'CNPJ' : (documento.length === 9 ? 'RG' : 'DOC'));


      let mascarado = ''
      if (documentoPromoterMask === 0) {
        mascarado = gerarMascaraCPF(documento)
      } else if (documentoPromoterMask === 1) {
        mascarado = gerarMascaraCNPJ(documento)
      }
      else if (documentoPromoterMask === 2) {
        mascarado = gerarMascaraRG(documento)
      }
      else {
        mascarado = documento
      }


      return `${documentoPromoterLabel} ${mascarado}`

    }


    watch(
      () => [store.getters.modalIngressoEmissao.display],
      () => {
        if (store.getters.modalIngressoEmissao.display) {
          data.value = store.getters.modalIngressoEmissao.ingresso
        } else {
          data.value = {}
        }
      })

    return { store, close, assets, data, dataEvento, mask, gerarMascaraCPF, gerarMascaraCPFOcultado };
  },
}
</script>

<style>
.custom-list-style ol {
  list-style-type: decimal !important;
  font-size: 14px;
}

.custom-list-style ul {
  list-style-type: disc !important;
  font-size: 14px;
}

.custom-list-style li {
  margin-left: 15px;
}

.pos {
  margin-top: 10px;
  margin-left: 35px;
}
</style>