import { createStore } from "vuex";

const store = createStore({
  state: {
    name: "Vue",
    alert: {
      display: false,
      title: "",
      message: "",
      type: "",
    },
    modal: {
      display: false,
      title: "",
      message: "",
      input: "",
      confirmMessage: "",
      callbackOK: "",
      callbackCancel: "",
    },
    modalIngresso: {
      display: false,
      ingresso: "",
    },
    modalIngressoEmissao: {
      display: false,
      ingresso: "",
    },
    modalVincularPromoter: {
      display: false,
      promoter: "",
      callbackOK: () => {},
    },
    modalEditarPromoter: {
      display: false,
      promoter: "",
      callbackOK: () => {},
    },
    modalVincularPromoterOutroEvento: {
      display: false,
      evento: "",
      callbackOK: () => {},
    },
    modalVisualizarEmissao: {
      display: false,
      emissao: ""
    },
    modalPromoterMovimentacaoGeral: {
      display: false,
      promoter: "",
      callbackOK: () => {}
    },
    modalPromoterMovimentacao: {
      display: false,
      promoter: "",
      callbackOK: () => {}
    },
    modalConvidarColaborador: {
      display: false,
      evento: "",
      callbackOK: () => {}
    },
    modalCadastroPainel: {
      display: false,
      callbackOK: () => {},
    },
    modalPagamentoPix: {
      display: false,
      data: "",
      pixInfo: "",
      callbackOK: () => {}
    },
    modalVendaPix: {
      display: false,
      emissao: "",
      callbackOK: () => {}
    },
    modalAcessarApp: {
      display: false,
      callbackOK: () => {}
    },
    modalConfirmarEmissao: {
      display: false,
      emissao: "",
      callbackOK: () => {}
    },
    modalLiberarIngresso: {
      display: false,
      liberacaoIngresso: "",
      callbackOK: () => {}
    },
    modalExcluirPromoterPermanente: {
      display: false,
      promoter: "",
      callbackOK: () => {}
    },
    modalDesvincularPromoter: {
      display: false,
      promoter: "",
      callbackOK: () => {}
    },
    modalRealizarPagamentoPromoter: {
      display: false,
      promoter: "",
      callbackOK: () => {}
    },
    modalExcluirEmissao: {
      display: false,
      callbackOK: () => {},
    },
    modalCancelarHistoricoEmissao: {
      display: false,
      emissao: "",
      callbackOK: () => {},
    },
    modalConfirmation: {
      display: false,
      msg: "",
      callbackOK: () => {},
    },
    modalLogout: {
      display: false,
      msg: "",
      callbackOK: () => {},
    },
    modalSucessoSuporte: {
      display: false,
      msg: "",
      callbackOK: () => {},
    },

    modalTrocaEvento: {
      display: false,
    },

    loading: false,

    userid: "",

    account: {
      id: 0,
      email: "",
      admin: false,
    },

    evento: {
      id: 0,
      nome: "",
      data: "",
      data_inicio: "",
      data_fim: "",
      year: "",
    },

    headerDropdown: {
      display: false,
    },

    filters: {
      data: [],
    },

    printReport: {
      data: []
    },

    canPrint: false,

    modalRetirada: {
      display: false
    },

    dadosFinanceiro: {
      valorTotal: 0,
      retiradas: [],
      pagamentos: 0,
      provisionamento: [],
      calendario: []
    },

    modalSairRetirada: {
      display: false
    },

    modalRetiradaConcluida: {
      display: false
    },

    modalPromoter: {
      display: false,
      promoter: {},
      callbackOK: () => {},
    },

    modalValorPago: {
      display: false,
      valores: []
    },

    modalExtrato: {
      display: false,
      promoter: {},
      valores: []
    },

    modalPagamento: {
      display: false,
      dados_pagamento: {},
      promoter: {}
    },

    modalCadastroPromoters: {
      display: false,
      evento: {},
    },

    updateTablePromoter: false,
  },

  getters: {
    alert: (state) => state.alert,
    loading: (state) => state.loading,
    modal: (state) => state.modal,
    modalTrocaEvento: (state) => state.modalTrocaEvento,
    modalConfirmation: (state) => state.modalConfirmation,
    modalLogout: (state) => state.modalLogout,
    modalSucessoSuporte: (state) => state.modalSucessoSuporte,
    modalIngresso: (state) => state.modalIngresso,
    modalIngressoEmissao: (state) => state.modalIngressoEmissao,
    modalVincularPromoter: (state) => state.modalVincularPromoter,
    modalEditarPromoter: (state) => state.modalEditarPromoter,
    modalVincularPromoterOutroEvento: (state) => state.modalVincularPromoterOutroEvento,
    modalVisualizarEmissao: (state) => state.modalVisualizarEmissao,
    modalPromoterMovimentacaoGeral: (state) => state.modalPromoterMovimentacaoGeral,
    modalPromoterMovimentacao: (state) => state.modalPromoterMovimentacao,
    modalConvidarColaborador: (state) => state.modalConvidarColaborador,
    modalCadastroPainel: (state) => state.modalCadastroPainel,
    modalPagamentoPix: (state) => state.modalPagamentoPix,
    modalVendaPix: (state) => state.modalVendaPix,
    modalAcessarApp: (state) => state.modalAcessarApp,
    modalConfirmarEmissao: (state) => state.modalConfirmarEmissao,
    modalLiberarIngresso: (state) => state.modalLiberarIngresso,
    modalExcluirPromoterPermanente: (state) => state.modalExcluirPromoterPermanente,
    modalDesvincularPromoter: (state) => state.modalDesvincularPromoter,
    modalRealizarPagamentoPromoter: (state) => state.modalRealizarPagamentoPromoter,
    modalExcluirEmissao: (state) => state.modalExcluirEmissao,
    modalCancelarHistoricoEmissao: (state) => state.modalCancelarHistoricoEmissao,
    user: (state) => state.userid,
    evento: (state) => state.evento,
    account: (state) => state.account,
    headerDropdown: (state) => state.headerDropdown,
    filters: (state) => state.filters,
    printReport: (state) => state.printReport,
    canPrint: (state) => state.canPrint,
    modalRetirada: (state) => state.modalRetirada,
    dadosFinanceiro: (state) => state.dadosFinanceiro,
    modalSairRetirada: (state) => state.modalSairRetirada,
    modalRetiradaConcluida: (state) => state.modalRetiradaConcluida,
    modalPromoter: (state) => state.modalPromoter,
    modalValorPago: (state) => state.modalValorPago,
    modalExtrato: (state) => state.modalExtrato,
    modalPagamento: (state) => state.modalPagamento,
    modalCadastroPromoters: (state) => state.modalCadastroPromoters,
    updateTablePromoter: (state) => state.updateTablePromoter,
  },

  mutations: {
    changeName: (state, value) => (state.name = value),
    loading: (state, value) => (state.loading = value),
    alert: (state, value) => (state.alert = value),
    modal: (state, value) => (state.modal = value),
    modalIngresso: (state, value) => (state.modalIngresso = value),
    modalIngressoEmissao: (state, value) => (state.modalIngressoEmissao = value),
    modalVincularPromoter: (state, value) => (state.modalVincularPromoter = value),
    modalEditarPromoter: (state, value) => (state.modalEditarPromoter = value),
    modalVincularPromoterOutroEvento: (state, value) => (state.modalVincularPromoterOutroEvento = value),
    modalVisualizarEmissao: (state, value) => (state.modalVisualizarEmissao = value),
    modalPromoterMovimentacaoGeral: (state, value) => (state.modalPromoterMovimentacaoGeral = value),
    modalPromoterMovimentacao: (state, value) => (state.modalPromoterMovimentacao = value),
    modalConvidarColaborador: (state, value) => (state.modalConvidarColaborador = value),
    modalCadastroPainel: (state, value) => (state.modalCadastroPainel = value),
    modalPagamentoPix: (state, value) => (state.modalPagamentoPix = value),
    modalVendaPix: (state, value) => (state.modalVendaPix = value),
    modalAcessarApp: (state, value) => (state.modalAcessarApp = value),
    modalConfirmarEmissao: (state, value) => (state.modalConfirmarEmissao = value),
    modalLiberarIngresso: (state, value) => (state.modalLiberarIngresso = value),
    modalExcluirPromoterPermanente: (state, value) => (state.modalExcluirPromoterPermanente = value),
    modalDesvincularPromoter: (state, value) => (state.modalDesvincularPromoter = value),
    modalRealizarPagamentoPromoter: (state, value) => (state.modalRealizarPagamentoPromoter = value),
    modalExcluirEmissao: (state, value) => (state.modalExcluirEmissao = value),
    modalCancelarHistoricoEmissao: (state, value) => (state.modalCancelarHistoricoEmissao = value),
    modalConfirmation: (state, value) => (state.modalConfirmation = value),
    modalLogout: (state, value) => (state.modalLogout = value),
    modalSucessoSuporte: (state, value) => (state.modalSucessoSuporte = value),
    modalTrocaEvento: (state, value) => (state.modalTrocaEvento = value),
    modalPromoter: (state, value) => (state.modalPromoter = value),
    modalValorPago: (state, value) => (state.modalValorPago = value),
    modalExtrato: (state, value) => (state.modalExtrato = value),
    user: (state, value) => (state.userid = value),
    updateTablePromoter: (state, value) => (state.updateTablePromoter = value),

    setEvento: (state, value) => (state.evento = value),
    setAccount: (state, value) => (state.account = value),
    headerDropdown: (state, value) => (state.headerDropdown = value),
    filters: (state, value) => (state.filters = value),
    printReport: (state, value) => (state.printReport = value),
    canPrint: (state, value) => (state.canPrint = value),
    modalRetirada: (state, value) => (state.modalRetirada = value),
    dadosFinanceiro: (state, value) => (state.dadosFinanceiro = value),
    modalSairRetirada: (state, value) => (state.modalSairRetirada = value),
    modalRetiradaConcluida: (state, value) => (state.modalRetiradaConcluida = value),
    modalPagamento: (state, value) => (state.modalPagamento = value),
    modalCadastroPromoters: (state, value) => (state.modalCadastroPromoters = value),
  },
});

export default store;
