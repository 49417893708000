<template>
  <div v-if="store.getters.modalRealizarPagamentoPromoter.display" x-transition:enter="transition ease-out duration-150"
    x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100"
    x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
    x-transition:leave-end="opacity-0"
    class="fixed max-h-screen inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center">
    <!-- Modal -->
    <div x-transition:enter="transition ease-out duration-150"
      x-transition:enter-start="opacity-0 transform translate-y-1/2" x-transition:enter-end="opacity-100"
      x-transition:leave="transition ease-in duration-150" x-transition:leave-start="opacity-100"
      x-transition:leave-end="opacity-0  transform translate-y-1/2"
      class="w-full lg:h-auto h-full bg-gray-200 rounded-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl"
      role="dialog" id="modal">

      <div class="rounded-lg h-full w-full overflow-auto">
        <div class="headerCard rounded-t-lg"> Adicionar pagamento para
          <span class="font-bold">{{ promoterV.promoter }}</span>
        </div>

        <div class="flex flex-col p-5 gap-7">
          <div class="flex flex-row gap-4">
            <VueDatePicker v-model="dateP" ref="dp" :enableTimePicker="false" locale="br" placeholder="Data" class=""
              :disabled="checkHoje" :teleport="true" :format="formatDate" :max-date="maxDate" auto-apply>
              <template #action-row="{ selectDate }">
                <div class="action-row flex justify-center w-full">
                  <button class="select-button" @click="selectDate">Selecionar</button>
                </div>
              </template>
            </VueDatePicker>

            <div class="flex flex-row gap-2 items-center">
              <input class="cursor-pointer" type="checkbox" v-model="checkHoje" />
              <label>Hoje</label>
            </div>
          </div>

          <div class="w-full">
            <input v-model="valor" @input="formatValue" type="text" class="valor w-full" placeholder="Valor" />
          </div>


        </div>

        <div class="flex items-end justify-between mt-2 p-5">
          <button class="botaoFechar" @click="close">Cancelar</button>
          <button class="botaoConfirmar" @click="confirmar">Confirmar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useStore } from "vuex";
import { ref, watch, inject } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { POST } from "@/services/api";

export default {
  components: {
    VueDatePicker,
  },
  setup() {
    const alert: any = inject("alert");
    const store = useStore();
    const promoterV = ref()
    const eventoInfo = inject("evento") as any;
    const eventoID = eventoInfo.getEventoID()
    const eventoNome = ref('')
    const dateP = ref()
    const checkHoje = ref(false)
    const valor = ref()
    const maxDate = ref(new Date())

    eventoNome.value = eventoInfo.getEvento().nome

    const formatDate = (date: Date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${day}/${month}/${year}`;
    };

    const formatDateEnvio = (date: Date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      const hours = String(new Date().getHours()).padStart(2, '0')
      const minutes = String(new Date().getMinutes()).padStart(2, '0');
      const seconds = String(new Date().getSeconds()).padStart(2, '0')

      return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }

    const formatValue = () => {
      const cleanValue = valor.value.replace(/\D/g, '');
      const formattedValue = (Number(cleanValue) / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      });
      valor.value = formattedValue;
    };

    const close = () => {
      valor.value = ''
      dateP.value = ''
      checkHoje.value = false
      store.commit("modalRealizarPagamentoPromoter", { display: false, promoter: {}, callbackOK: () => { } });
    };

    const confirmar = async () => {

      let dataEnviar: string;

      if (!dateP.value && !checkHoje.value) {
        alert.open(
          "Atenção",
          "Selecione pelo menos uma data",
          "warning"
        );
      }

      else if (!valor.value) {
        alert.open(
          "Atenção",
          "Digite um valor!",
          "warning"
        );
      } else {

        if (checkHoje.value)
          dataEnviar = formatDateEnvio(new Date())
        else
          dataEnviar = formatDateEnvio(dateP.value)

        try {
          const response = await POST("promoter_movements/create_payment", {
            promoters_usuario_id: promoterV.value.id_promoter_usuario,
            evento_id: eventoID,
            valor: parseFloat(valor.value.replace(/\D/g, '').replace(',', '.')) / 100,
            saldo: parseFloat(valor.value.replace(/\D/g, '').replace(',', '.')) / 100,
            data: dataEnviar
          })

          if (response.success) {
            checkHoje.value = false
            dateP.value = null
            valor.value = null
            store.getters.modalRealizarPagamentoPromoter.callbackOK();
          }

        } catch (error) {
          checkHoje.value = false
          dateP.value = null
          valor.value = null
          alert.open(
            "Atenção",
            error,
            "warning"
          );
          console.log(error)
        }

      }

    };



    watch(
      () => [store.getters.modalRealizarPagamentoPromoter.display],
      () => {
        if (store.getters.modalRealizarPagamentoPromoter.display) {
          promoterV.value = store.getters.modalRealizarPagamentoPromoter.promoter
        } else {
          promoterV.value = {}
        }
      })


    return { store, close, promoterV, eventoID, eventoNome, confirmar, dateP, checkHoje, valor, formatDate, maxDate, formatValue };
  },
}
</script>


<style>
.dp__theme_light {
  --dp-primary-color: #911945 !important;
}

.dp__menu {
  box-shadow: 0 3px 12px 0 rgb(0 0 0/0.15);
}

.dp__range_end,
.dp__range_start,
.dp__active_date {
  background: #7D1D54 !important;
  color: #ffffff !important;
  border: 1px solid #ffffff;
  font-weight: 600;
  border-radius: 5.42px;
}

.dp__input {
  height: 45px;
  border: transparent !important;
  border-radius: 3px;
  color: #5E5E5E;
  font-size: 16px;
}

.dp__range_between {
  background: #911945 !important;
  color: #F1F2F6 !important;
  border-radius: 5.42px;
}

.dp__today {
  border: 1px solid #911945 !important;
}
</style>

<style scoped>
.valor {
  border: transparent;
  height: 45px;
  border-radius: 3px;
  background-color: white;
  padding-left: 15px;
}

.botaoFechar {
  border: 1px solid #361D54;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
}

.botaoFechar:hover {
  background-color: #361D54;
  color: white;
}

.botaoConfirmar {
  border: 1px solid #408135;
  border-radius: 3.32px;
  padding: 7px 15px 7px 15px;
  background-color: #408135;
  color: white;
}

.botaoConfirmar:hover {
  background-color: #2a5523;
  color: white;
}

.campoDados {
  color: #5E5E5E;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  padding: 3px 0px 3px 15px;
}

.headerCard {
  background-color: #361D54;
  text-align: center;
  color: white;
  padding: 15px;
}

input[type="checkbox"] {
  appearance: none;
  height: 15px;
  width: 15px;
  background: #ffffff;
  border-radius: 4px;
}

input[type="checkbox"]:checked {
  height: 15px;
  width: 15px;
  background: #7c1d55;
  border-radius: 4px;
}

.img {
  width: 150px;
  height: 150px;
}
</style>